import { useDictStore } from '@/store'
import { getDicts } from '@/api'

/**
 * 获取字典数据
 */
export function useDict(...args) {
  const res = ref({});
  const dictStore = useDictStore()
  return (() => {
    args.forEach((dictType, index) => {
      res.value[dictType] = [];
      const dicts = dictStore.getDict(dictType);
      if (dicts) {
        res.value[dictType] = dicts;
      } else {
        getDicts(dictType).then(resp => {
          res.value[dictType] = resp.data.map(p => ({ label: p.dictLabel, value: p.dictValue, elTagType: p.listClass, elTagClass: p.cssClass }))
          dictStore.setDict(dictType, res.value[dictType]);
        })
      }
    })
    return toRefs(res.value);
  })()
}
export function useDictNocache(...args) {
  const res = ref({});
  const dictStore = useDictStore()
  return (() => {
    args.forEach((dictType, index) => {
      res.value[dictType] = [];
        getDicts(dictType).then(resp => {
          res.value[dictType] = resp.data.map(p => ({ label: p.dictLabel, value: p.dictValue, elTagType: p.listClass, elTagClass: p.cssClass }))
          dictStore.setDict(dictType, res.value[dictType]);
        })
    })
    return toRefs(res.value);
  })()
}