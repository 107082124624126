import { createApp } from 'vue'
import 'uno.css'
import '@/assets/styles/index.scss' // global css
import App from './App'
import { setupStore } from './store'
import router from './router'
import directive from './directive' // directive

// 注册指令
import plugins from './plugins' // plugins
import { download, useDict, useDictNocache, parseTime, resetForm, addDateRange, handleTree, selectDictLabel, selectDictLabels } from '@/utils'

// svg图标
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/SvgIcon'
import elementIcons from '@/components/SvgIcon/svgicon'

import './permission' // permission control
import '@/utils/fingerprint'

// 分页组件
import Pagination from '@/components/Pagination'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 自定义树选择组件
import TreeSelect from '@/components/TreeSelect'
// 字典标签组件
import DictTag from '@/components/DictTag'
// AppContainer
import AppContainer from '@/components/AppContainer'
async function setupApp() {
  const app = createApp(App)
  setupStore(app)

// 全局方法挂载
  app.config.globalProperties.useDict = useDict
  app.config.globalProperties.useDictNocache = useDictNocache
  app.config.globalProperties.download = download
  app.config.globalProperties.parseTime = parseTime
  app.config.globalProperties.resetForm = resetForm
  app.config.globalProperties.handleTree = handleTree
  app.config.globalProperties.addDateRange = addDateRange
  app.config.globalProperties.selectDictLabel = selectDictLabel
  app.config.globalProperties.selectDictLabels = selectDictLabels
  directive(app)

  // 全局组件挂载
  app
  .component('DictTag', DictTag)
  .component('Pagination', Pagination)
  .component('TreeSelect', TreeSelect)
  .component('FileUpload', FileUpload)
  .component('ImageUpload', ImageUpload)
  .component('ImagePreview', ImagePreview)
  .component('RightToolbar', RightToolbar)
  .component('AppContainer', AppContainer)
  .component('Editor', Editor)
  .use(router)
  .use(plugins)
  .use(elementIcons)
  .component('svg-icon', SvgIcon)
  .mount('#app')

  // 使用element-plus 并且设置全局的大小
  // app.use(ElementPlus, {
  //   locale: locale, 
  //   size: Cookies.get('size') || 'default' // 支持 large、default、small
  // })
}

setupApp()