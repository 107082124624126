<template>
  <router-view v-slot="{ Component, route }">
    <transition name="fade-transform" mode="out-in">
      <section class="app-main" :class="route.path.slice(1)" :key="route.path">
        <keep-alive :include="tagsViewStore.cachedViews">
          <component :is="Component" />
        </keep-alive>
      </section>
    </transition>
  </router-view>
  <iframe-toggle />
</template>

<script setup>
import iframeToggle from "./IframeToggle/index"
import { useTagsViewStore } from '@/store'

const tagsViewStore = useTagsViewStore()
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100% - 50px);
  // width: 100%;
  position: relative;
  overflow: hidden;
  &.chat {
    display: flex;
    background-color: #f7f7f7;
    overflow: visible;
  }
}

.fixed-header + .app-main {
  padding-top: var(--navbar-height);
}

.hasTagsView {
  .app-main {
    /* 90 = navbar + tags-view = 50 + 40 */
    min-height: calc(100% - var(--tags-view-height) - var(--navbar-height));
  }

  .fixed-header + .app-main {
    padding-top: calc(var(--tags-view-height) + var(--navbar-height));
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    // padding-right: 6px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 3px;
}
</style>

