<template>
  <div class="app-container">
    <slot></slot>
  </div>
</template>
<style scoped>
.app-container {
  padding: 20px;
  font-size: 16px;
  box-sizing: border-box;
}
</style>