import { request } from '@/utils'

/**
 * 登录方法 login
 * @param {Object} data
 * @param {string} data.userName
 * @param {string} data.password
 * @param {string} data.code
 * @param {string} data.googleCode
 * @param {string} data.uuid
 * @param {string} data.lang
 * @returns {Object}
 */
export function login(data) {
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captcha/image',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 获取系统信息
export function getSystemInfo() {
  return request({
    url: '/system/info',
    method: 'get'
  })
}

