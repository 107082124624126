const number = ref(0);
const uploadList = ref([]);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const baseUrl = import.meta.env.VITE_APP_BASE_API;
const uploadAvatar = ref(import.meta.env.VITE_APP_BASE_API + "/common/upload"); // 上传的图片服务器地址


// 上传前loading加载
function handleBeforeUpload(file) {
    let isImg = false;
    if (props.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = props.fileType.some(type => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
        });
    } else {
        isImg = file.type.indexOf("image") > -1;
    }
    if (!isImg) {
        proxy.$modal.msgError(
            `文件格式不正确, 请上传${props.fileType.join("/")}图片格式文件!`
        );
        return false;
    }
    if (props.fileSize) {
        const isLt = file.size / 1024 / 1024 < props.fileSize;
        if (!isLt) {
            proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`);
            return false;
        }
    }
    proxy.$modal.loading("正在上传图片，请稍候...");
    number.value++;
}
// 文件个数超出
function handleExceed() {
    proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
}

// 上传成功回调
function handleUploadSuccess(res, file) {
    if (res.code === 200) {
        uploadList.value.push({ name: res.fileName, url: res.fileName });
        uploadedSuccessfully();
    } else {
        number.value--;
        proxy.$modal.closeLoading();
        proxy.$modal.msgError(res.msg);
        proxy.$refs.imageUpload.handleRemove(file);
        uploadedSuccessfully();
    }
}

// 删除图片
function handleDelete(file) {
    const findex = fileList.value.map(f => f.name).indexOf(file.name);
    if (findex > -1 && uploadList.value.length === number.value) {
        fileList.value.splice(findex, 1);
        emit("update:modelValue", listToString(fileList.value));
        return false;
    }
}

// 上传结束处理
function uploadedSuccessfully() {
    if (number.value > 0 && uploadList.value.length === number.value) {
        fileList.value = fileList.value.filter(f => f.url !== undefined).concat(uploadList.value);
        uploadList.value = [];
        number.value = 0;
        emit("update:modelValue", listToString(fileList.value));
        proxy.$modal.closeLoading();
    }
}

// 上传失败
function handleUploadError() {
    proxy.$modal.msgError("上传图片失败");
    proxy.$modal.closeLoading();
}

// 预览
function handlePictureCardPreview(file) {
    dialogImageUrl.value = file.url;
    dialogVisible.value = true;
}
