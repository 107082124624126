export default {
  mounted(el) {
    el.addEventListener('input', () => {
      const input = el.querySelector('input');
      if (input) {
        const originalValue = input.value;
        const newValue = originalValue.replace(/[\u4e00-\u9fa5]/g, '');
        if (originalValue !== newValue) {
          input.value = newValue;
          const event = new Event('input', { bubbles: true });
          input.dispatchEvent(event);
        }
      }
    });
  }
};
