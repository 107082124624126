import print from 'vue3-print-nb'
import hasRole from './permission/hasRole'
import hasPermissions from './permission/hasPermissions'
import copyText from './common/copyText'
import noChinese from './common/noChinese'

export default function directive(app) {
    app.directive('hasRole', hasRole)
    app.directive('hasPermissions', hasPermissions)
    app.directive('copyText', copyText)
    app.directive('noChinese', noChinese)
    app.directive('print', print)
}