<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { useSettingsStore } from '@/store'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { handleThemeStyle } from '@/utils'

onMounted(() => {
  nextTick(() => {
    // 初始化主题样式
    handleThemeStyle(useSettingsStore().theme)
  })
})
</script>
