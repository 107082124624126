import { ElMessage, ElMessageBox, ElNotification, ElLoading } from "element-plus";
import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
import "element-plus/theme-chalk/el-notification.css";
let loadingInstance;
let messageInstance;
let notifyInstance;

export default {
    // 消息提示
    msg(content) {
        messageInstance?.close()
        messageInstance = ElMessage.info(content);
    },
    // 错误消息
    msgError(content) {
        messageInstance?.close()
        messageInstance = ElMessage.error(content);
    },
    // 成功消息
    msgSuccess(content) {
        messageInstance?.close()
        messageInstance = ElMessage.success(content);
    },
    // 警告消息
    msgWarning(content) {
        messageInstance?.close()
        messageInstance = ElMessage.warning(content);
    },
    // 弹出提示
    alert(content) {
        ElMessageBox.alert(content, "系统提示");
    },
    // 错误提示
    alertError(content) {
        ElMessageBox.alert(content, "系统提示", { type: "error" });
    },
    // 成功提示
    alertSuccess(content) {
        ElMessageBox.alert(content, "系统提示", { type: "success" });
    },
    // 警告提示
    alertWarning(content) {
        ElMessageBox.alert(content, "系统提示", { type: "warning" });
    },
    // 通知提示
    notify(content) {
        notifyInstance?.close()
        notifyInstance = ElNotification.info(content);
        return notifyInstance
    },
    // 错误通知
    notifyError(content) {
        notifyInstance?.close()
        notifyInstance = ElNotification.error(content);
    },
    // 成功通知
    notifySuccess(content) {
        notifyInstance?.close()
        notifyInstance = ElNotification.success(content);
    },
    // 警告通知
    notifyWarning(content) {
        notifyInstance?.close()
        notifyInstance = ElNotification.warning(content);
    },
    // 确认窗体
    confirm(content, title, type = 'warning') {
        return ElMessageBox.confirm(content, title ?? "系统提示", {
            type,
        });
    },
    // 提交内容
    prompt(content, type = 'warning') {
        return ElMessageBox.prompt(content, "系统提示", {
            type,
        });
    },
    // 打开遮罩层
    loading(content) {
        loadingInstance = ElLoading.service({
            lock: true,
            text: content,
            background: "rgba(0, 0, 0, 0.7)",
        });
    },
    // 关闭遮罩层
    closeLoading() {
        loadingInstance.close();
    },
};
